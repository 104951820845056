/** @file
* Archivo para generar actualizaciones en la sección de portadas y acciones rápidas
*/
import onmount from 'onmount';

onmount('.new_employee_portal_communication_resource, .edit_employee_portal_communication_resource', async function () {
  const form = $(this);
  const selector = form.find('#select-url-options');
  const messageUrlContent = form.find('.message-url');
  const messageFileUrlContent = form.find('.message-file-url');

  await import('@assets/javascripts/vendor/select2');
  selector.select2({ minimumResultsForSearch: Infinity, width: '100%' });

  selector.change(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    if (option === '0') {
      messageUrlContent.hide('slow');
      messageFileUrlContent.hide('slow');
      messageFileUrlContent.find('input').val('');
      messageUrlContent.find('textarea').val('');
    }
    if (option === '1') {
      messageUrlContent.show('slow');
      messageFileUrlContent.hide('slow');
      messageFileUrlContent.find('input').val('');
    }
    if (option === '2') {
      messageUrlContent.hide('slow');
      messageFileUrlContent.show('slow');
      messageUrlContent.find('textarea').val('');
    }
  });
});
/**
 * Método para bloquear los checkbox en Fiji
 * Esto es temporal, ya que se deben bloquear las publicaciones sin feed_item
 */
onmount('#index_publications-resource #table_publications-resource', function () {
  const $selfTable = $(this);
  const domWithFeedItem = $selfTable.find('[data-has-feed-item="false"]');
  domWithFeedItem.each(function (_, item) {
    const publicationId = item.getAttribute('data-publication-id');
    const checkbox = $selfTable.find(`#checkbox-${publicationId}_publications-resource`);
    checkbox.prop('disabled', true);
    if (checkbox.length === 1) {
      const contentHtml = checkbox[0].parentNode.innerHTML;
      checkbox[0].parentNode.innerHTML = contentHtml;
    }
  });
});

/**
 * Método para remover los checkbox cuando se hace la descarga múltiple por área
 * Esto es temporal, por slicing se desarrollará un Job para descargar el archivo
 * de publicaciones por áreas
 */
onmount('#index_publications-resource #body_publications-resource', function () {
  const $selfTable = $(this);
  const buttonFiji = $selfTable.find('[id="batch-action-multiple_download_publications-resource"');
  buttonFiji.on('click', function () {
    setTimeout(() => {
      const buttonKey = '.swal2-confirm.modal-button-accept.download-by-area-publication-resource';
      $(buttonKey).on('click', () => setTimeout(toggleCkeckbox, 500));
    }, 1000);
  });

  function toggleCkeckbox() {
    const primaryCheck = $selfTable.find('#checkbox-select-all_publications-resource');
    if (primaryCheck.length === 1 && primaryCheck[0].checked) {
      primaryCheck.trigger('click');
    }
    else {
      const checkboxList = $selfTable.find('#table_publications-resource input[name="fiji-rows-checkbox"]');
      $.each(checkboxList, function (_, item) {
        if (item.checked) {
          $(item).trigger('click');
        }
      });
    }
  }

});
