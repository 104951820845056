/** @file Instalación de Product Fruits mediante GTM para tour guiado en prueba gratis Mexico
 *  src: https://help.productfruits.com/en/article/installation-overview
*/

function parseJSON(json) {
  return JSON.parse(json || 'null');
}

const userInfo = parseJSON(document.getElementById('buk-user-info')?.text);

if (userInfo) {
  document.addEventListener('DOMContentLoaded', () => {
    const username = btoa(userInfo.email);

    if (!window.dataLayer) { window.dataLayer = []; }
    const usernameExists = window.dataLayer.some(item => item.username === username);
    if (!usernameExists) {
      window.dataLayer.push({ username: username });
      window.dataLayer.push({ event: 'user-data-set' });
    }
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        // eslint-disable-next-line no-negated-condition
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-K6GRGZCZ');
  });
}
