/** @file
 * Archivo encargado de la lógica para desplegar el organigrama de empleados.
 * Uso en el organigrama general de la empresa y organigrama por cada división.
 */
import onmount from 'onmount';

const loadDependencies = async function loadDependencies(url) {
  const { getOrgChartData, loadOrgChart } = await import('./orgchart-v2.js');
  const html2canvasModule = await import('html2canvas');
  window.html2canvas = html2canvasModule.default;
  await loadOrgChart();
  const data = await getOrgChartData(url);
  return data;
};

onmount('#orgchart-employee-v2', async function () {
  let data;
  try {
    data = await loadDependencies($(this).data('url'));
  }
  catch (error) {
    // Ignoramos los errores de networking ya que no tenemos mucho que hacer aquí
    // El status 0 indica este tipo de error
    if (error.status === 0) {
      $('#orgchart-employee-v2-fail').removeClass('d-none');
      $('#orgchart-employee-v2-loader').addClass('d-none');
      return;
    }
    throw error;
  }
  const exportFilename = $('#orgchart-employee-v2').attr('data-filename');
  const org = $(this).orgchart({
    'exportButton': true,
    'exportFilename': exportFilename,
    'exportButtonName': '-',
    'data': data,
    'zoom': true,
    'zoomoutLimit': 0.25,
    'pan': true,
    'nodeContent': 'title',
    'visibleLevel': 1,
    nodeEnterLeaveHandler: null,
    'createNode': function ($node, nodeData) {
      $node.attr('data-level', nodeData.level);
      let contentToAppend = `
        <figure class="mb-1">
          ${buildTagImg(nodeData)}
          ${buildIconHonorario(nodeData)}
        </figure>
        <p class="c-name font-size-14 fw-600 mb-2">
          <span title="${getFullName(nodeData)}">${getFullName(nodeData)}</span>
        </p>
        <hr>
        <p class="c-job font-size-12 fw-900 text-basic-700 mb-1">
          <span title="${getJob(nodeData)}">${getJob(nodeData)}</span>
        </p>`;
      if (getProperty(nodeData, 'division')) {
        contentToAppend += `
          <p class="font-size-12 fw-500 text-basic-700 mb-0">
            <span title="${getProperty(nodeData, 'division_tooltip')}">${getProperty(nodeData, 'division')}</span>
          </p>
          <p class="font-size-12 fw-500 text-basic-700 mb-0">
            <span title="${getProperty(nodeData, 'department_tooltip')}">${getProperty(nodeData, 'department')}</span>
          </p>
        `;
      }
      $node.find('.content').append(contentToAppend);
      $node.children('.bottomEdge')
        .append(`<div class='node-children-text'>${nodeData.children.length}</div>`);
      $node.children('.edge').removeClass('oci-chevron-up');
      $node.children('.edge').addClass('oci-chevron-down');
    },
    'initCompleted': function (_$chart) {
      $('#orgchart-employee-v2-loader').addClass('d-none');
      var $container = $('#orgchart-employee-v2');
      $container.scrollLeft(($container[0].scrollWidth - $container.width()) / 2);
      $('#export-orgchart-png').on('click',
        async function () {
          $('#export-orgchart-png').prop('disabled', true);
          $('.bottomEdge').css('display', 'none');
          var $mask = $container.find('.mask');
          if($mask.length === 0) {
            $container.append(`
              <div class="mask d-flex justify-content-center align-items-center">
                <i class="buk-loader">
              </i></div>
            `);
          }
          else {
            $mask.removeClass('hidden');
          }
          await convertImagesToBase64(_$chart);
          $('.oc-export-btn').click();
          $('.oc-export-btn').promise().done(function () {
            $('.bottomEdge').css('display', 'flex');
            $('.bottomEdge').css('flex-direction', 'row');
            $('#export-orgchart-png').prop('disabled', false);
          });
        }
      );
      $('.node').unbind('mouseenter').unbind('mouseleave');
      _$chart.unbind('mousedown').unbind('touchstart');
      _$chart.unbind('mouseup').unbind('touchend');
      _$chart.on('mousedown touchstart', customPanStartHandler);
      _$chart.on('mouseup touchend', customPanEndHandler);

      const box = document.querySelector('.orgchart-header');
      box.addEventListener('gesturestart', function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });

      box.addEventListener('gesturechange', function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });

      box.addEventListener('gestureend', function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const orgchartWidth = entry.target.style.width || $('.orgchart').width();
          const containerWidth = $('#orgchart-employee-v2').width();
          if(containerWidth >  orgchartWidth) {
            $('.orgchart').css('transform', 'matrix(1, 0, 0, 1, 1, 1)');
          }
        }
      });
      resizeObserver.observe(document.querySelector('.orgchart'));
    },
  });
  $('.node').off('click', '.bottomEdge');
  $('.node').on('click', '.bottomEdge', customBottomEdgeClickHandler(org));
  org.$chartContainer.off('wheel');
  $(document).off('touchmove');
  org.$chartContainer.on('wheel', { 'oc': org }, customZoomWheelHandler(org));
  $('#zoom-in').on('click', function () {
    var newScale  = 1.2;
    org.setChartScale(org.$chart, newScale);
  });
  $('#zoom-out').on('click', function () {
    var newScale  = 0.8;
    org.setChartScale(org.$chart, newScale);
  });
  $(document).on('touchmove', { 'oc': org }, customZoomingHandler(org));
});

function buildTagImg(nodeData) {
  let src = '/images/photo_error.jpg';
  let res = '';
  let isImage = true;
  if (nodeData.image) {
    src = nodeData.image;
    if(nodeData.image.toLowerCase().endsWith('.svg')) {
      res = `<svg crossorigin="anonymous" style="background-image: url(${src}); background-size: contain"/></svg>`;
      isImage = false;
    }
  }
  if(isImage) {
    res = `
      <svg
        width="120"
        height="120"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1">
        <g>
          <pattern id="grump_avatar-${nodeData.id}" width="120" height="120" patternUnits="userSpaceOnUse">
            <image
              href="${src}"
              width="120"
              height="120"
              x="0"
              y="0">
            </image>
          </pattern>
          <rect x="0" width="120" height="120" rx="8" style="fill: url(#grump_avatar-${nodeData.id});" />
        </g>
      </svg>
    `;
  }
  return res;
}

function convertImagesToBase64(_$chart) {
  const promises = [];
  const $elements = [];
  _$chart.find('.node').filter((_index, element) => {
    if(customGetNodeState($(element)).visible === true) {
      if($(element).find('pattern image').length > 0) {
        $elements.push($(element).find('pattern image')[0]);
      }
    }
  });

  $($elements).map(function () {
    const promise = $.Deferred();
    const i = this;
    if($(i).attr('href').toLowerCase().startsWith('http') || $(i).attr('href') === '/images/photo_error.jpg') {
      var img = new Image();
      img.onload = function () {
        var newCanvas = document.createElement('canvas');
        newCanvas.width = img.width;
        newCanvas.height = img.height;
        var ctx = newCanvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = newCanvas.toDataURL('image/png');
        $(i).attr('href', dataURL);
        promise.resolve();
      };
      // Don't fail on error, just continue
      img.onerror = () => promise.resolve();
      img.crossOrigin = 'anonymous';
      img.src = $(i).attr('href') + '?123123';
      promises.push(promise);
    }
  });
  return Promise.all(promises);
}

function customGetNodeState($node) {
  const $target = $node;
  if ($target.length) {
    if (!(($target.closest('.nodes').length && $target.closest('.nodes').is('.hidden')) ||
      ($target.closest('.hierarchy').length && $target.closest('.hierarchy').is('.hidden')) ||
      ($target.closest('.vertical').length && ($target.closest('.nodes').is('.hidden') ||
      $target.closest('.vertical').is('.hidden')))
    )) {
      return { 'exist': true, 'visible': true };
    }
    return { 'exist': true, 'visible': false };
  }
}

function buildIconHonorario(nodeData) {
  if (nodeData.isHonorario)
    return `<span class="material-icons">access_time</span>`;
  return '';
}

function getFullName(nodeData) {
  let fullName = '';
  if (nodeData.name)
    fullName = nodeData.name + ' ' + nodeData.last_name;

  return fullName;
}

function getJob(nodeData) {
  let job = '';
  if (nodeData.position_first_line) {
    job = nodeData.position_first_line;
  }
  if (nodeData.position_second_line) {
    job += ' ' + nodeData.position_second_line;
  }
  return job;
}

function getProperty(nodeData, propName) {
  return nodeData[propName] || '';
}

function customPanStartHandler(e) {
  const $chart = $(e.delegateTarget);
  if(e.type === 'touchstart' && e.touches && e.touches.length > 1) {
    $chart.data('panning', false);
    return;
  }
  $chart.css('cursor', 'move').data('panning', true);
  let lastX = 0;
  let lastY = 0;
  const lastTf = $chart.css('transform');
  if (lastTf !== 'none') {
    const temp = lastTf.split(',');
    if (lastTf.indexOf('3d') === -1) {
      lastX = parseInt(temp[4]);
      lastY = parseInt(temp[5]);
    }
    else {
      lastX = parseInt(temp[12]);
      lastY = parseInt(temp[13]);
    }
  }
  let startX = 0;
  let startY = 0;
  if (!e.targetTouches) { // pand on desktop
    startX = e.pageX - lastX;
    startY = e.pageY - lastY;
  }
  else if (e.targetTouches.length === 1) { // pan on mobile device
    startX = e.targetTouches[0].pageX - lastX;
    startY = e.targetTouches[0].pageY - lastY;
  }
  else if (e.targetTouches.length > 1) {
    return;
  }
  $chart.on('mousemove touchmove', function (ev) {
    if (!$chart.data('panning')) {
      return;
    }
    let newX = 0;
    let newY = 0;
    if (!ev.targetTouches) { // pand on desktop
      newX = ev.pageX - startX;
      newY = ev.pageY - startY;
    }
    else if (ev.targetTouches.length === 1) { // pan on mobile device
      newX = ev.targetTouches[0].pageX - startX;
      newY = ev.targetTouches[0].pageY - startY;
    }
    else if (ev.targetTouches.length > 1) {
      return;
    }
    const lastTfAux = $chart.css('transform');
    if (lastTfAux === 'none') {
      if (lastTfAux.indexOf('3d') === -1) {
        $chart.css('transform', 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')');
      }
      else {
        $chart.css('transform', 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + newX + ', ' + newY + ', 0, 1)');
      }
    }
    else {
      const matrix = lastTfAux.split(',');
      if (lastTfAux.indexOf('3d') === -1) {
        matrix[4] = ' ' + newX;
        matrix[5] = ' ' + newY + ')';
      }
      else {
        matrix[12] = ' ' + newX;
        matrix[13] = ' ' + newY;
      }
      $chart.css('transform', matrix.join(','));
    }
  });
}

function customPanEndHandler(e) {
  const $chart = $(e.delegateTarget);
  if ($chart.data('panning')) {
    $chart.data('panning', false).css('cursor', 'move').off('mousemove');
  }
}

function customBottomEdgeClickHandler(_$chart) {
  return function (event) {
    event.stopPropagation();
    var $bottomEdge = $(event.target);
    var $node = $(event.delegateTarget);
    var childrenState = _$chart.getNodeState($node, 'children');
    if (childrenState.exist) {
      var $children = $node.siblings('.nodes').children().children('.node');
      if ($children.is('.sliding')) { return; }
      // hide the descendant nodes of the specified node
      if (childrenState.visible) {
        customHideChildren($node, _$chart);
        _$chart.triggerHideEvent($node, 'children');
      }
      else { // show the descendants
        const firstOpenedLevel = Number($node[0].getAttribute('data-level')) + 1;
        customShowChildren($node, _$chart, firstOpenedLevel);
        _$chart.triggerShowEvent($node, 'children');
      }
    }
    else { // load the new children nodes of the specified node by ajax request
      if (_$chart.startLoading($bottomEdge)) {
        var opts = _$chart.options;
        var url = $.isFunction(opts.ajaxURL.children) ?
          opts.ajaxURL.children($node.data('nodeData')) :
          opts.ajaxURL.children + $node[0].id;
        _$chart.loadNodes('children', url, $bottomEdge);
      }
    }
  };
}

function customZoomWheelHandler(_$chart) {
  return function (e) {
    var oc = e.data.oc;
    var factor = isTrackPad(e.originalEvent) ? 0.03 : 0.07;
    e.preventDefault();
    var newScale  = 1 + (e.originalEvent.deltaY > 0 ? -1 * factor : factor);
    oc.setChartScale(oc.$chart, newScale);
  };
}

function isTrackPad(e) {
  var isTrackpad = false;
  isTrackpad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0;
  return isTrackpad;
}

function customZoomingHandler(_$chart) {
  return function (e) {
    var oc = e.data.oc;
    oc.$chart.data('pinching', true);
    if(oc.$chart.data('pinching')) {
      var dist = oc.getPinchDist(e);
      oc.$chart.data('pinchDistEnd', dist);
      var diff = oc.$chart.data('pinchDistEnd') - oc.$chart.data('pinchDistStart');
      if (diff > 0) {
        oc.setChartScale(oc.$chart, 1.07);
      }
      else if (diff < 0) {
        oc.setChartScale(oc.$chart, 0.93);
      }
      oc.$chart.data('pinchDistStart', oc.$chart.data('pinchDistEnd'));
      oc.$chart.data('pinching', false);
    }
  };
}

function customShowChildren($node, _$chart, firstOpenedLevel) {
  $node.closest('.hierarchy').removeClass('isChildrenCollapsed').addClass('isOpen');
  // Se agrega porque la librería hace un toggle al ícono
  const thisLevel = Number($node[0].getAttribute('data-level'));
  if($node.children('.verticalEdge').hasClass('oci-chevron-up') && firstOpenedLevel === thisLevel) {
    $node.children('.verticalEdge').removeClass('oci-chevron-up').addClass('oci-chevron-down');
  }
  var $levels = $node.siblings('.nodes');
  var isVerticalDesc = $levels.is('.vertical');
  var $animatedNodes = isVerticalDesc
    ? $levels.removeClass('hidden').find('.node').filter(_$chart.isVisibleNode.bind(_$chart))
    : $levels.removeClass('hidden').children('.hierarchy').find('.node:first')
      .filter(_$chart.isVisibleNode.bind(_$chart));
  if (!isVerticalDesc) {
    $animatedNodes.closest('.hierarchy').removeClass('isCollapsedDescendant');
    $levels.find('.hierarchy.isOpen > div').filter((_index, element) => {
      customShowChildren($(element), _$chart, firstOpenedLevel);
    });
  }
  _$chart.repaint($animatedNodes.get(0));
  $animatedNodes.addClass('sliding').removeClass('slide-up').eq(0).one('transitionend',
    { 'node': $node, 'animatedNodes': $animatedNodes },
    _$chart.showChildrenEnd.bind(_$chart));
}

function customHideChildren($node, _$chart) {
  $node.closest('.hierarchy').addClass('isChildrenCollapsed');
  $node.closest('.hierarchy.isOpen').removeClass('isOpen');
  var $lowerLevel = $node.siblings('.nodes');
  _$chart.stopAjax($lowerLevel);
  var $animatedNodes = $lowerLevel.find('.node').filter(function (_index, element) {
    return Number(element.getAttribute('data-level')) === (Number($node[0].getAttribute('data-level')) + 1);
  });

  var $lowerNextLevel = $lowerLevel.find('.node').filter(function (_index, element) {
    return Number(element.getAttribute('data-level')) > (Number($node[0].getAttribute('data-level')) + 1);
  });
  $lowerNextLevel.addClass('slide-up');
  $animatedNodes.addClass('sliding slide-up').eq(0).one(
    'transitionend',
    { 'animatedNodes': $animatedNodes, 'lowerLevel': $lowerLevel, 'node': $node },
    _$chart.hideChildrenEnd.bind(_$chart)
  );
}
